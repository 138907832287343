<template>
  <v-container fluid>
    <v-toolbar
      color="primary"
      dark
    >
      <span>Employees</span>
      <v-spacer></v-spacer>
      <v-icon>mdi-account</v-icon>
    </v-toolbar>
    <v-alert
      type="error"
      dismissible
      outlined
      v-for="(item, index) in empGetErrors"
      :key="index"
    >
      {{ item.first() }}
    </v-alert>
    <v-data-table
      :items="empList"
      :loading="empGetting"
      :headers="headers"
      :search="search"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" lg="3" md="3">
            <v-text-field
              label="search"
              v-model="search"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td class="text-uppercase">{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>
            <tooltip-btn
              fab
              small
              color="yellow"
              tooltip="Edit"
              btn-class="mr-2"
              @click="$router.push({ name: 'employees.edit', params: { employee: item.id } })"
            >
              <v-icon>mdi-pencil</v-icon>
            </tooltip-btn>
            <tooltip-btn
              fab
              small
              color="green"
              tooltip="Rate & Allowance"
              @click="$emit('open-dialog', { employeeId: item.id, employeeName: item.name })"
            >
              <v-icon>mdi-account-cash</v-icon>
            </tooltip-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import empRepository from '@/repositories/employee.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const getDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Config', sortable: false },
  ],
  search: null,
})

export default {
  name: 'EmployeeTable',
  components: {
    tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  data () {
    return {
      ...getVars('emp'),
      ...tableVars(),
    }
  },
  created () {
    this.getEmployees()
  },
  methods: {
    getEmployees () {
      if (!this.empGetting) {
        this.empGetting = true
        getDelay(() => {
          empRepository.all()
            .then(({ data }) => {
              this.empList = data.map(e => {
                const woMiddlename = `${e.firstname} ${e.lastname}`
                const name = e.middlename ? `${e.firstname} ${e.middlename.substr(0, 1)}. ${e.lastname}` : woMiddlename
                return {
                  ...e,
                  name,
                }
              })
            })
            .catch(e => { this.empGetErrors = errorHandler(e) })
            .then(() => { this.empGetting = false })
        })
      }
    },
  },
}
</script>
